import type { NextRouter } from 'next/router';
import type { UrlObject } from 'url';

type Url = UrlObject | string;

export const navigateTo = (url: Url, router: NextRouter) => {
  router.push(url).catch(() => {
    window.location.href = url.toString();
  });
};
