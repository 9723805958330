import { useEffect, useRef, useState } from 'react';
import {
  Anchor,
  Button,
  Card,
  Grid,
  Group,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useFocusTrap } from '@mantine/hooks';
import { IconAt } from '@tabler/icons-react';
import logo from 'images/large/logo_colors_black.png';
import Image from 'next/image';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { signIn } from 'next-auth/react';

import { Error } from 'components/auth/Error';
import { SupportLink } from 'components/ui/SupportLink';
import { defaultToastNotification, toast } from 'components/utils/toast';
import { useOrganizationCreateTrialUser } from 'utils/api/organization/use-organization-create-trial-user';

import classes from './EmailLogin.module.css';

interface EmailLoginProps {
  type: 'login' | 'signup' | 'trial';
}

export const EmailLogin = ({ type }: EmailLoginProps) => {
  const focusTrapRef = useFocusTrap();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { mutate: createOrgAndUser } = useOrganizationCreateTrialUser({
    onError: (err) => {
      if (err.data?.code === 'CONFLICT') {
        toast.error('A user already exists with this email, try logging in');
      } else {
        defaultToastNotification();
      }

      setIsLoading(false);
    },
    onSuccess: (email) => {
      void signIn('email', {
        email,
      });
      setIsLoading(false);
    },
  });

  const searchParams = useSearchParams();
  const error = searchParams?.get('error');
  const email = searchParams?.get('email') || '';

  const form = useForm({
    initialValues: {
      email,
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const isTrial = type === 'trial';
  const isSigningUp = type === 'signup';
  const isLogginIn = !isTrial && !isSigningUp;

  useEffect(() => {
    if (email) {
      form.setValues({ email });
      form.resetDirty();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  if (error) {
    return <Error error={error} />;
  }

  return (
    <Card withBorder p={24} ref={focusTrapRef}>
      <form
        onSubmit={form.onSubmit((values) => {
          setIsLoading(true);
          if (isTrial) {
            createOrgAndUser(values);
          } else {
            signIn('email', { email: values.email }).catch(() => {
              defaultToastNotification();
              setIsLoading(false);
            });
          }
        })}
      >
        <Grid gutter={16} justify="center">
          <Grid.Col span={12}>
            <div className={classes.wrapper}>
              <Image
                src={logo}
                alt="BiggerPicture"
                style={{
                  objectFit: 'contain',
                  padding: 'var(--logo-padding)',
                }}
                fill
              />
            </div>
          </Grid.Col>
          <Grid.Col span={12}>
            {isTrial && (
              <>
                <Text mb="sm">
                  {
                    "Try the BiggerPicture Appointment Scheduling Network. Once you receive a link in your inbox, you'll be able to sign in and start your warehouse trial."
                  }
                </Text>
                <Text>{`Enter your email address to sign up:`}</Text>
              </>
            )}
            {isSigningUp && (
              <>
                <Text mb="sm">
                  {
                    "Join the BiggerPicture Appointment Scheduling Network. Once you receive a link in your inbox, you'll be able to choose whether to sign up as a carrier/broker, facility, or customer."
                  }
                </Text>
                <Text>{`Enter your email address to sign up:`}</Text>
              </>
            )}
            {isLogginIn && (
              <Text>{`Enter your email address to sign in:`}</Text>
            )}
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              name="email"
              ref={inputRef}
              data-autofocus
              leftSection={<IconAt size={18} />}
              placeholder="Your email"
              type="email"
              required
              {...form.getInputProps('email')}
            />
          </Grid.Col>
          <Grid.Col span={12} />
          <Grid.Col>
            <Group justify="center">
              <Button
                type="submit"
                loading={isLoading}
                disabled={
                  form.values.email.length < 1 ||
                  !inputRef.current?.checkValidity()
                }
              >
                {isSigningUp
                  ? 'Verify email address'
                  : 'Send me a sign in link'}
              </Button>
            </Group>
          </Grid.Col>
          <Grid.Col span={12}>
            <Grid gutter={10} style={{ textAlign: 'center' }}>
              <Grid.Col mt={20}>
                {isSigningUp ? (
                  <Text>
                    {'Already have an account? '}
                    <Anchor component={Link} href="/auth/signin">
                      Sign in
                    </Anchor>
                  </Text>
                ) : (
                  <Text>
                    {'Need an account? '}
                    <Anchor component={Link} href="/signup">
                      Sign up
                    </Anchor>
                  </Text>
                )}
              </Grid.Col>
              <Grid.Col span={12} />
              <Grid.Col>
                <SupportLink />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </form>
    </Card>
  );
};
