import type { ReactQueryOptions } from 'utils/trpc';
import { trpc } from 'utils/trpc';

export const useOrganizationCreateTrialUser = (
  options: ReactQueryOptions['organization']['createTrialUser'],
) => {
  return trpc.organization.createTrialUser.useMutation({
    ...options,
    onSuccess: (...args) => {
      options.onSuccess?.(...args);
    },
  });
};
